




































































































































































import SearchBuilder from "@/builder/SearchBuilder";
import SelectRack from "@/components/custom/select/SelectRack.vue";
import UploadWarehouseTransferAttachment from "@/components/WarehouseTransfer/UploadWarehouseTransferAttachment.vue";
import { debounce } from "@/helpers/debounce";
import useProduct from "@/hooks/useProduct";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { ProductDetailDto } from "@/models/interface/master-product";
import { ProductPurchaseResponseDto } from "@/models/interface/ProductPurchaseResponse.interface";
import {
  CreateWarehouseTransferFormState,
  CreateWarehouseTransferFormStateLine,
} from "@/models/interface/warehouse-transfer/CreateWarehouseTransferFormState.interface";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  components: {
    SelectRack,
    UploadWarehouseTransferAttachment,
  },
})
export default class WarehouseTransferMutateForm extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Prop({ required: false, type: Boolean, default: false })
  pending!: boolean;

  @Prop({ required: false, type: Object, default: undefined })
  defaultState!: CreateWarehouseTransferFormState;

  @Ref("formModel")
  formModel!: FormModel;

  formState: CreateWarehouseTransferFormState =
    new CreateWarehouseTransferFormState();

  formRules = {
    from: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    to: [{ required: true, message: this.$t("lbl_validation_required_error") }],
  };

  columns = [
    {
      title: this.$t("lbl_part"),
      dataIndex: "part",
      scopedSlots: { customRender: "part" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      title: this.$t("lbl_total_qty"),
      dataIndex: "totalQty",
      scopedSlots: { customRender: "totalQty" },
      width: 100,
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      scopedSlots: { customRender: "uom" },
      width: 200,
    },
  ];

  loading = {
    partOptions: false,
  };

  selectedRowKeys: string[] = [];
  partOptions: Option<InventoryLineResponseDto>[] = [];

  get isNew(): boolean {
    return this.formState.status === "NEW";
  }

  async getPartOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): Promise<Option<InventoryLineResponseDto>[]> {
    const parts = await logisticServices.getListInventoryLine(params);
    return parts.data.map(part => ({
      key: part.product.id,
      value: part.product.id,
      label: part.product.name,
      meta: part,
    }));
  }

  setDefaultFormState(state: CreateWarehouseTransferFormState): void {
    this.formState = { ...state };
  }

  setPartOptionsForEachRow(): void {
    const params = new RequestQueryParams();
    if (this.formState.from?.key) {
      params.search = new SearchBuilder()
        .push(["warehouseLocation.secureId", this.formState.from.key])
        .build();
    }
    this.loading.partOptions = true;
    this.getPartOptions(params)
      .then(options => {
        this.partOptions = options;
      })
      .finally(() => {
        this.loading.partOptions = false;
      });
  }

  setSerialNumberOptionsForEachRow(): void {
    const { findAllSnForSales } = useProduct();
    const builder = new SearchBuilder();

    const locationId = this.formState.from?.key;

    const promises: Promise<ProductPurchaseResponseDto[]>[] = [];
    this.defaultState.warehouseTransferLines.forEach(async line => {
      const queries: string[] = [];
      if (locationId) {
        queries.push(builder.push(["locationId", locationId]).build());
      }
      if (line.part?.key) {
        queries.push(builder.push(["productId", line.part.key]).build());
      }

      const params = new RequestQueryParams();
      params.search = queries.join(SearchBuilder.AND);
      promises.push(findAllSnForSales(params));
    });

    this.formState.warehouseTransferLines.forEach(line => {
      line.loadingSerialNumberOptions = true;
    });
    Promise.all(promises)
      .then(responses => {
        responses.forEach((res, index) => {
          this.formState.warehouseTransferLines[index].serialNumberOptions =
            res.map(sales => ({
              key: sales.serialNumber,
              label: sales.serialNumber,
              value: sales.serialNumber,
            }));
        });
      })
      .finally(() => {
        this.formState.warehouseTransferLines.forEach(line => {
          line.loadingSerialNumberOptions = false;
        });
      });
  }

  setUOMOptionsForEachRow(): void {
    const promises: Promise<ProductDetailDto>[] = [];
    this.defaultState.warehouseTransferLines.forEach(async line => {
      if (line.part?.key) {
        promises.push(productService.getDetailProduct(line.part.key));
      }
    });

    this.formState.warehouseTransferLines.forEach(line => {
      line.loadingUOMOptions = true;
    });
    Promise.all(promises)
      .then(responses => {
        responses.forEach((res, index) => {
          this.formState.warehouseTransferLines[index].uomOptions =
            res.uomConversions.map(uom => ({
              key: uom.uomSourceId,
              label: uom.uomConversion,
              value: uom.uomSourceId,
            }));
        });
      })
      .finally(() => {
        this.formState.warehouseTransferLines.forEach(line => {
          line.loadingUOMOptions = false;
        });
      });
  }

  @Watch("defaultState")
  handleDefaultStateChange(): void {
    if (this.defaultState) {
      this.setDefaultFormState(this.defaultState);
      this.setPartOptionsForEachRow();
      this.setSerialNumberOptionsForEachRow();
      this.setUOMOptionsForEachRow();
    }
  }

  handleSelectedRowKeysChange(keys: string[]): void {
    this.selectedRowKeys = keys;
  }

  handleAddRow(): void {
    this.formState.warehouseTransferLines.push(
      new CreateWarehouseTransferFormStateLine()
    );
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.formState.warehouseTransferLines =
        this.formState.warehouseTransferLines.filter(line => {
          const keep = !this.selectedRowKeys.includes(line.rowId);

          if (!keep && !!line.id) {
            this.formState.deletedLineIds.push(line.id);
          }

          return keep;
        });

      this.selectedRowKeys = [];
    });
  }

  handleFromChange(part?: LabelInValue): void {
    if (!part?.key) {
      this.partOptions = [];
      this.formState.warehouseTransferLines.forEach(line => {
        line.part = undefined;
        line.partOptions = [];
        line.usePartOptions = false;
        line.uom = undefined;
        line.uomOptions = [];
        line.serialNumber = undefined;
        line.serialNumberOptions = [];
      });
      return;
    }

    const params = new RequestQueryParams();
    params.search = new SearchBuilder()
      .push(["warehouseLocation.secureId", part.key])
      .build();

    this.loading.partOptions = true;
    this.getPartOptions(params)
      .then(options => {
        this.partOptions = options;
        this.formState.warehouseTransferLines.forEach(line => {
          line.part = undefined;
          line.partOptions = [];
          line.usePartOptions = false;
          line.uom = undefined;
          line.uomOptions = [];
        });
      })
      .finally(() => {
        this.loading.partOptions = false;
      });
  }

  handlePartSearch(
    row: CreateWarehouseTransferFormStateLine,
    value: string
  ): void {
    debounce(() => {
      const params = new RequestQueryParams();
      const builder = new SearchBuilder();
      const queries: string[] = [];

      if (value) {
        const nameFilter = builder
          .push(["product.name", value], { like: "both" })
          .or()
          .push(["product.code", value], { like: "both" })
          .build();
        queries.push(nameFilter);
      }

      if (this.formState.from?.key) {
        const warehouseLocationFilter = new SearchBuilder()
          .push(["warehouseLocation.secureId", this.formState.from.key])
          .build();
        queries.push(warehouseLocationFilter);
      }

      params.search = queries.join(SearchBuilder.AND);
      row.loadingPartOptions = true;
      this.getPartOptions(params)
        .then(options => {
          row.partOptions = options;
          row.usePartOptions = true;
        })
        .finally(() => {
          row.loadingPartOptions = false;
        });
    });
  }

  handlePartChange(
    row: CreateWarehouseTransferFormStateLine,
    value: LabelInValue | undefined
  ): void {
    row.uom = undefined;
    row.uomOptions = [];
    row.serialNumber = undefined;
    row.serialNumberOptions = [];

    if (!value?.key) {
      return;
    }

    row.loadingUOMOptions = true;
    productService
      .getDetailProduct(value.key)
      .then(res => {
        row.uomOptions = res.uomConversions.map(uom => ({
          key: uom.uomSourceId,
          label: uom.uomConversion,
          value: uom.uomSourceId,
        }));

        if (row.uomOptions[0]) {
          row.uom = {
            key: String(row.uomOptions[0].key),
            label: row.uomOptions[0].label,
          };
        }
      })
      .finally(() => {
        row.loadingUOMOptions = false;
      });

    const { findAllSnForSales } = useProduct();
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queries: string[] = [];

    if (this.formState.from?.key) {
      queries.push(
        builder.push(["locationId", this.formState.from.key]).build()
      );
    }
    queries.push(builder.push(["productId", value.key]).build());

    params.search = queries.join(SearchBuilder.AND);

    row.loadingSerialNumberOptions = true;
    findAllSnForSales(params)
      .then(res => {
        row.serialNumberOptions = res.map(sn => ({
          key: sn.serialNumber,
          label: sn.serialNumber,
          value: sn.serialNumber,
        }));
      })
      .finally(() => {
        row.loadingSerialNumberOptions = false;
      });
  }

  handleBack(): void {
    this.$emit("back");
  }

  handleReset(): void {
    this.formState = new CreateWarehouseTransferFormState();
  }

  handleSaveAsDraft(): void {
    this.$emit("saveAsDraft", this.formState);
  }

  handleCreate(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      for (const line of this.formState.warehouseTransferLines) {
        if (!line.part || !line.uom) {
          this.showNotifWarning("notif_validation_error");
          return;
        }
      }

      this.$emit("create", this.formState);
    });
  }
}
