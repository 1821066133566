var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.isEdit
          ? _vm.$t("common.detail-text", {
              text: _vm.$t("lbl_customer_maintenance")
            })
          : _vm.$t("lbl_maintenance_result"),
        loading: _vm.loader
      }
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { column: 4, layout: "vertical" } },
            [
              _vm.workOrder.customerMaintenanceNumber
                ? _c(
                    "a-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("common.number-text", {
                          text: _vm.$t("lbl_customer_maintenance")
                        }),
                        "data-testid": "rwo-form-cust-maintenance-number"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.workOrder.customerMaintenanceNumber) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.workOrder.csfNumber
                ? _c(
                    "a-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("common.number-text", {
                          text: _vm.$t("lbl_csf")
                        }),
                        "data-testid": "rwo-form-csf-number"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.workOrder.csfNumber) + " ")]
                  )
                : _vm._e(),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("common.number-text", {
                      text: _vm.$t("lbl_work_order")
                    }),
                    "data-testid": "rwo-form-wo-number"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.workOrder.workOrderNumber) + " ")]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_work_order_date"),
                    "data-testid": "rwo-form-wo-date"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("date")(_vm.workOrder.workOrderDate)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_pic_name"),
                    "data-testid": "rwo-form-pic"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.workOrder.picName || "-") + " ")]
              ),
              _vm.workOrder.journalId
                ? _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_journal") } },
                    [
                      _c(
                        "RouterLink",
                        {
                          attrs: {
                            to: {
                              name: "generaljournal.detail",
                              params: { id: _vm.workOrder.journalId }
                            },
                            "data-testid": "rwo-form-journal"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.workOrder.journalNumber) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_customer")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { layout: "vertical", column: 4 } },
            [
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_name"),
                    "data-testid": "rwo-form-cust-name"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.workOrder.customerName || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_address"),
                    "data-testid": "rwo-form-cust-address"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.workOrder.customerAddress || "-") + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_maintenance")) + " ")]),
          _c(
            "a-descriptions",
            { attrs: { layout: "vertical", column: 4 } },
            [
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_mechanic"),
                    "data-testid": "rwo-form-mechanic-name"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.workOrder.mechanicName || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_schedule_order"),
                    "data-testid": "rwo-form-schedule-order"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("date")(_vm.workOrder.scheduleOrder)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_description"),
                    "data-testid": "rwo-form-description"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.workOrder.description || "-") + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_form")) + " ")]),
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formState,
                layout: "vertical",
                "wrapper-col": { span: 18 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_status"),
                            prop: "status",
                            rules: {
                              required: !_vm.isEdit,
                              message: _vm.$t("lbl_validation_required_error")
                            }
                          }
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: { "data-testid": "rwo-form-status" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formState.status) + " "
                                  )
                                ]
                              )
                            : _c("a-select", {
                                attrs: {
                                  "data-testid": "rwo-form-status",
                                  placeholder: _vm.$t("common.select-text", {
                                    text: _vm.$t("lbl_status")
                                  }),
                                  loading: _vm.loading.status,
                                  options: _vm.statusOptions
                                },
                                on: { change: _vm.onChangeStatus },
                                model: {
                                  value: _vm.formState.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formState, "status", $$v)
                                  },
                                  expression: "formState.status"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_reason"),
                            prop: "reason",
                            rules: {
                              required: !_vm.isEdit,
                              message: _vm.$t("lbl_validation_required_error")
                            }
                          }
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: { "data-testid": "rwo-form-reason" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formState.reason) + " "
                                  )
                                ]
                              )
                            : _c("a-select", {
                                attrs: {
                                  "data-testid": "rwo-form-reason",
                                  placeholder: _vm.$t("common.select-text", {
                                    text: _vm.$t("lbl_reason")
                                  }),
                                  loading: _vm.loading.reason,
                                  options: _vm.reasonOptions,
                                  disabled: !_vm.formState.status
                                },
                                model: {
                                  value: _vm.formState.reason,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formState, "reason", $$v)
                                  },
                                  expression: "formState.reason"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isCancel
                    ? _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_cancel_reason"),
                                prop: "cancelReason"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid": "rwo-form-cancel-reaason"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formState.cancelReason) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCancel
                    ? _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_cancel_date"),
                                prop: "cancelDate"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid": "rwo-form-cancel-date"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formState.cancelDate) + " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_date_time"),
                            prop: "completeDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              "data-testid": "rwo-form-complete-date",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_date_time")
                              }),
                              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                              "disabled-date": _vm.minDateRules,
                              "show-time": "",
                              disabled: _vm.isEdit
                            },
                            model: {
                              value: _vm.formState.completeDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "completeDate", $$v)
                              },
                              expression: "formState.completeDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_notes"),
                            prop: "notes",
                            rules: {
                              required: !_vm.isEdit,
                              message: _vm.$t("lbl_validation_required_error")
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              !_vm.isEdit
                                ? {
                                    key: "extra",
                                    fn: function() {
                                      return [
                                        _c("character-length", {
                                          attrs: { value: _vm.formState.notes }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: { "data-testid": "rwo-form-notes" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formState.notes) + " "
                                  )
                                ]
                              )
                            : _c("a-textarea", {
                                attrs: {
                                  "data-testid": "rwo-form-notes",
                                  placeholder: _vm.$t("common.insert-text", {
                                    text: _vm.$t("lbl_notes")
                                  }),
                                  "auto-size": { minRows: 3, maxRows: 5 }
                                },
                                model: {
                                  value: _vm.formState.notes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formState, "notes", $$v)
                                  },
                                  expression: "formState.notes"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-table", {
                        attrs: {
                          "data-source": _vm.formState.products,
                          columns: _vm.columns,
                          size: "small",
                          pagination: false,
                          "row-class-name": function(_record, index) {
                            return index % 2 ? "bg-white" : "bg-gray-light"
                          },
                          scroll: { x: 1000, y: 500 },
                          "data-testid": "rwo-form-table"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "a-row",
                                  {
                                    attrs: {
                                      type: "flex",
                                      justify: "space-between"
                                    }
                                  },
                                  [
                                    _c("a-col", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_product")) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "a-col",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "rwo-form-table-total-item"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("lbl_total_items", {
                                                total:
                                                  _vm.formState.products.length
                                              })
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "returnedQty",
                            fn: function(_, record) {
                              return [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "rwo-form-table-returned-qty"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("qty")(record.returnedQty)
                                          )
                                        )
                                      ]
                                    )
                                  : _c("a-input-number", {
                                      staticClass: "w-100",
                                      attrs: {
                                        "data-testid":
                                          "rwo-form-table-returned-qty",
                                        parser: _vm.reverseFormatNumber,
                                        formatter: _vm.formatterNumber,
                                        precision: _vm.storeBaseDecimalPlace
                                      },
                                      model: {
                                        value: record.returnedQty,
                                        callback: function($$v) {
                                          _vm.$set(
                                            record,
                                            "returnedQty",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "record.returnedQty"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "isTrackAsAsset",
                            fn: function(_, record) {
                              return [
                                _c("a-switch", {
                                  attrs: {
                                    disabled: _vm.isEdit,
                                    "data-testid": "rwo-form-table-track-asset"
                                  },
                                  model: {
                                    value: record.isTrackAsAsset,
                                    callback: function($$v) {
                                      _vm.$set(record, "isTrackAsAsset", $$v)
                                    },
                                    expression: "record.isTrackAsAsset"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                {
                  staticClass: "mt-5",
                  attrs: { type: "flex", justify: "end", gutter: 16 }
                },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { "data-testid": "rwo-form-back" },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                          ),
                          _vm.$can("cancel", "result-work-order") &&
                          _vm.isEdit &&
                          !_vm.isCancel
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid": "rwo-form-cancel",
                                    loading: _vm.loading.cancel,
                                    type: "danger"
                                  },
                                  on: { click: _vm.handleCancel }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$can("create", "result-work-order") && !_vm.isEdit
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading: _vm.loading.submit,
                                    "data-testid": "rwo-form-save",
                                    "html-type": "submit",
                                    type: "primary"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CancelRWOModal", {
        attrs: { rwoId: _vm.rwoId, visible: _vm.modal.show },
        on: { close: _vm.handleCloseModal, success: _vm.handleCancelSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }