import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  ResultWorkOrderCreateRequestDto,
  ResultWorkOrderResponseDto,
} from "@/models/interface/result-work-order";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class ResultWorkOrderService extends HttpClient {
  create(
    payload: ResultWorkOrderCreateRequestDto
  ): Promise<ResultWorkOrderResponseDto> {
    return this.post<ResultWorkOrderResponseDto>(Api.WorkOrderResult, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancel(rwoId: string): Promise<never> {
    return this.post<never, never>(`${Api.WorkOrderResultCancel}/${rwoId}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetail(rwoId: string): Promise<ResultWorkOrderResponseDto> {
    return this.get<ResultWorkOrderResponseDto>(
      `${Api.WorkOrderResult}/${rwoId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const resultWorkOrderService = new ResultWorkOrderService();
