














import { generateUUID } from "@/helpers/uuid";
import { useValidateMaximumFileSize } from "@/hooks/file";
import MNotificationVue from "@/mixins/MNotification.vue";
import { logisticServices } from "@/services/logistic.service";
import { UploadFile } from "ant-design-vue/types/upload";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class UploadWarehouseTransferAttachment extends Mixins(
  MNotificationVue
) {
  loading = {
    uploadAttachment: false,
  };

  @Prop({ required: false, default: undefined })
  value!: string | undefined;

  attachments: UploadFile[] = [];

  @Watch("value")
  onValueChange() {
    if (!this.value) {
      this.attachments = [];
    } else {
      this.attachments = [
        {
          url: this.value,
          name: this.value,
          size: 1,
          type: "",
          uid: generateUUID(),
        },
      ];
    }
  }

  async handleUploadAttachment({ file }: { file: File }): Promise<void> {
    try {
      this.loading.uploadAttachment = true;
      const attachments = [...this.attachments];
      this.attachments = attachments.slice(-1);

      const fd = new FormData();
      fd.append("file", file);

      const response = await logisticServices.uploadAttachment(fd);
      this.$emit("input", response.url);
      this.attachments[0].url = response.url;

      this.showNotifSuccess("notif_file_upload_successfully", {
        filename: file.name,
      });
    } catch (error) {
      this.$emit("input", undefined);
      this.showNotifError("notif_file_upload_failed", {
        filename: file.name,
      });
    } finally {
      this.loading.uploadAttachment = false;
    }
  }

  handleBeforeUpload(file: UploadFile) {
    const isValid = this.validationFileSize(file);
    if (!isValid) return false;

    this.attachments.push(file);

    return true;
  }

  validationFileSize(file: UploadFile): boolean {
    if (useValidateMaximumFileSize(file.size)) {
      this.showNotifWarning("attachment.allow-size");
      return false;
    }

    return true;
  }

  handleRemoveAttachment(): boolean {
    this.attachments.splice(0, 1);
    this.$emit("input", undefined);
    return true;
  }
}
