


































































import { SearchBuilder } from "@/builder";
import { removeDuplicateObj } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { useWorkOrder } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { WorkOrderCsfDto } from "@/models/interface/csf";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  WorkOrderListResponseDto,
  WorkOrderResponseDto,
} from "@/models/interface/work-order";
import { csfService } from "@/services/csf.service";
import { workOrderService } from "@/services/work-order.service";
import { LabelInValue } from "@/types";
import { ResultWorkOrderFinderState } from "@interface/result-work-order";
import { FormModel } from "ant-design-vue";
import Vue from "vue";

export default Vue.extend({
  name: "ResultWorkOrderFinder",
  mixins: [MNotificationVue],
  props: {
    params: {
      required: false,
      default: undefined,
      type: Object as () => {
        woNumber: "";
        csfNumber: "";
      },
    },
  },
  data() {
    this.onSearchWoNumber = debounceProcess(this.onSearchWoNumber);
    this.onSearchCsfNumber = debounceProcess(this.onSearchCsfNumber);
    return {
      form: null as FormModel | null,
      workOrderOptions: [] as Option[],
      csfOptions: [] as Option[],
      loading: {
        wo: false,
        csf: false,
        find: false,
      },
      formState: {
        csfNumber: undefined,
        woNumber: undefined,
      } as ResultWorkOrderFinderState,
      validationSchema: {
        csfNumber: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        woNumber: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
    };
  },
  watch: {
    params: {
      immediate: true,
      handler: "autofill",
    },
  },
  mounted() {
    if (this.$refs.form) {
      this.form = this.$refs.form as FormModel;
    }

    const params = new RequestQueryParams();
    params.search = new SearchBuilder()
      .push(["workOrderCSFLine.workOrderCSF.status", "PROCESSED"], {
        not: true,
      })
      .build();
    this.fetchWorkOrderList(params);
  },
  methods: {
    async autofill(
      payload: { woNumber: string; csfNumber: string } | undefined
    ) {
      if (!payload) return;

      const { findList } = useWorkOrder();

      const searchByCsf = new SearchBuilder()
        .push(["documentNumber", payload.csfNumber])
        .build();
      const searchByWo = new SearchBuilder()
        .push(["workOrder.documentNumber", payload.woNumber])
        .build();
      const promises: [
        Promise<Pagination<WorkOrderCsfDto>>,
        Promise<Pagination<WorkOrderListResponseDto>>
      ] = [
        csfService.getList({ search: searchByCsf }),
        findList({ search: searchByWo }),
      ];

      try {
        const [resCsf, resWo] = await Promise.all(promises);
        const [csf] = resCsf.data;
        const [wo] = resWo.data;

        if (csf && wo) {
          this.formState.csfNumber = { key: csf.id, label: csf.csfNo };
          this.formState.woNumber = {
            key: wo.workOrderId,
            label: wo.workOrderNumber,
          };

          const searchByWo = new SearchBuilder()
            .push(["workOrder.secureId", this.formState.woNumber.key])
            .build();
          this.fetchCsfList({ search: searchByWo });
          this.findDocument(this.formState);
        }
      } catch {
        this.showNotifError("notif_process_fail");
      }
    },
    onSearchWoNumber(value?: string) {
      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["workOrderCSFLine.workOrderCSF.status", "PROCESSED"], {
          not: true,
        })
        .build();
      if (value) {
        params.search = new SearchBuilder()
          .push(["workOrder.documentNumber", value], { like: "both" })
          .and()
          .push(["workOrderCSFLine.workOrderCSF.status", "PROCESSED"], {
            not: true,
          })
          .build();
      }

      this.fetchWorkOrderList(params);
    },
    async fetchWorkOrderList(params?: RequestQueryParamsModel) {
      const { findList } = useWorkOrder();
      try {
        this.loading.wo = true;
        const response = await findList(params);
        const options = response.data.map(item => ({
          label: item.workOrderNumber,
          value: item.workOrderId,
          key: item.workOrderId,
          meta: item,
        }));
        this.workOrderOptions = removeDuplicateObj(options, "value");
      } catch {
        this.showNotifError("notif_process_fail");
        this.workOrderOptions = [];
      } finally {
        this.loading.wo = false;
      }
    },
    async fetchCsfList(params?: RequestQueryParamsModel) {
      try {
        this.loading.csf = true;
        const response = await csfService.getList(params);
        const options = response.data.map(item => ({
          label: item.csfNo,
          value: item.id,
          key: item.id,
          meta: item,
        }));
        this.csfOptions = options;
      } catch {
        this.showNotifError("notif_process_fail");
        this.csfOptions = [];
      } finally {
        this.loading.csf = false;
      }
    },
    onSearchCsfNumber(value?: string) {
      const params = new RequestQueryParams();

      params.search = new SearchBuilder()
        .push(["workOrder.secureId", this.formState.woNumber?.key ?? ""])
        .and()
        .push(["status", "UNPROCESSED"])
        .build();

      if (value) {
        params.search = new SearchBuilder()
          .push(["workOrder.secureId", this.formState.woNumber?.key ?? ""])
          .and()
          .push(["status", "UNPROCESSED"])
          .and()
          .push(["documentNumber", value], { like: "both" })
          .build();
      }

      this.fetchCsfList(params);
    },
    onChangeWo(value: LabelInValue) {
      this.formState.csfNumber = undefined;

      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["workOrder.secureId", value.key])
        .and()
        .push(["status", "UNPROCESSED"])
        .build();

      this.fetchCsfList(params);
    },
    validateForm() {
      this.form?.validate(valid => {
        if (!valid) return;

        this.findDocument(this.formState);
      });
    },
    async findDocument({ csfNumber, woNumber }: ResultWorkOrderFinderState) {
      try {
        this.loading.find = true;
        const response =
          await workOrderService.getListWorkOrderDetailByIdAndCsf(
            woNumber?.key ?? "",
            csfNumber?.key ?? ""
          );
        this.emitsData(response);
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading.find = false;
      }
    },
    emitsData(data: WorkOrderResponseDto) {
      this.$emit("success", { ...data });
    },
  },
});
