var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.create-text", {
          text: _vm.$t("lbl_result_customer_maintenance")
        })
      }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.formState,
            rules: _vm.validationSchema,
            layout: "horizontal",
            "wrapper-col": { span: 6 },
            "label-col": { span: 4 },
            "label-align": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("common.number-text", {
                  text: _vm.$t("lbl_work_order")
                }),
                prop: "woNumber"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  "label-in-value": "",
                  options: _vm.workOrderOptions,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_work_order")
                  }),
                  loading: _vm.loading.wo,
                  "filter-option": false,
                  "show-search": "",
                  "data-testid": "rwo-find-wo"
                },
                on: { search: _vm.onSearchWoNumber, change: _vm.onChangeWo },
                model: {
                  value: _vm.formState.woNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "woNumber", $$v)
                  },
                  expression: "formState.woNumber"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("common.number-text", {
                  text: _vm.$t("lbl_csf")
                }),
                prop: "csfNumber"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  "label-in-value": "",
                  options: _vm.csfOptions,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_csf")
                  }),
                  loading: _vm.loading.csf,
                  "filter-option": false,
                  "show-search": "",
                  disabled: !_vm.formState.woNumber,
                  "data-testid": "rwo-find-csf"
                },
                on: { search: _vm.onSearchCsfNumber },
                model: {
                  value: _vm.formState.csfNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "csfNumber", $$v)
                  },
                  expression: "formState.csfNumber"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { offset: 4 } } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    "html-type": "submit",
                    type: "primary",
                    "data-testid": "rwo-find",
                    loading: _vm.loading.find
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }