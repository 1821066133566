























import { resultWorkOrderService } from "@/services/result-work-order.service";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import MNotification from "@/mixins/MNotification.vue";

@Component({})
export default class CancelRWOModal extends Mixins(MNotification) {
  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: String, required: true })
  rwoId!: string;

  @Prop({ type: Boolean, default: false, required: false })
  visible = false;

  form: { reason: string } = {
    reason: "",
  };

  formRules = {
    reason: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = false;

  handleClose(): void {
    this.$emit("close");
  }

  handleSubmit(): void {
    this.formModel.validate(valid => {
      if (valid) {
        this.loading = true;
        resultWorkOrderService
          .cancel(this.rwoId, { cancelReason: this.form.reason })
          .then(() => {
            this.showNotifSuccess("notif_cancel_success");
            this.$emit("success");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }
}
