var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_warehouse_transfer") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    layout: "horizontal",
                    "label-align": "left",
                    model: _vm.formState,
                    rules: _vm.formRules,
                    "label-col": { sm: { span: 24 }, lg: { span: 5 } },
                    "wrapper-col": { sm: { span: 24 }, lg: { span: 19 } }
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_from"), prop: "from" } },
                    [
                      _c("SelectRack", {
                        attrs: { "value-as": "id" },
                        on: { change: _vm.handleFromChange },
                        model: {
                          value: _vm.formState.from,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "from", $$v)
                          },
                          expression: "formState.from"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_to"), prop: "to" } },
                    [
                      _c("SelectRack", {
                        attrs: { "value-as": "id", "by-user": false },
                        model: {
                          value: _vm.formState.to,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "to", $$v)
                          },
                          expression: "formState.to"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                    [
                      _c("a-date-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: ""
                        },
                        model: {
                          value: _vm.formState.date,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "date", $$v)
                          },
                          expression: "formState.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_note"), prop: "note" },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.formState.note }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: _vm.$t("lbl_note")
                        },
                        model: {
                          value: _vm.formState.note,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "note", $$v)
                          },
                          expression: "formState.note"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_attachment"),
                        prop: "attachment"
                      }
                    },
                    [
                      _c("UploadWarehouseTransferAttachment", {
                        model: {
                          value: _vm.formState.attachment,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "attachment", $$v)
                          },
                          expression: "formState.attachment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [_vm._v(" " + _vm._s(_vm.formState.status) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "row-key": "rowId",
                  columns: _vm.columns,
                  "data-source": _vm.formState.warehouseTransferLines,
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    },
                    showSizeChanger: true
                  },
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.handleSelectedRowKeysChange
                  },
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c(
                          "a-space",
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleAddRow }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_add_row")) + " "
                                )
                              ]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { type: "danger" },
                                on: { click: _vm.handleDeleteRow }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "part",
                    fn: function(_, row) {
                      return [
                        _c("a-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "label-in-value": "",
                            "filter-option": false,
                            "dropdown-match-select-width": false,
                            options: row.usePartOptions
                              ? row.partOptions
                              : _vm.partOptions,
                            loading:
                              _vm.loading.partOptions || row.loadingPartOptions,
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_part")
                            })
                          },
                          on: {
                            search: function($event) {
                              return _vm.handlePartSearch(row, $event)
                            },
                            change: function($event) {
                              return _vm.handlePartChange(row, $event)
                            }
                          },
                          model: {
                            value: row.part,
                            callback: function($$v) {
                              _vm.$set(row, "part", $$v)
                            },
                            expression: "row.part"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "serialNumber",
                    fn: function(_, row) {
                      return [
                        _c("a-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "label-in-value": "",
                            "dropdown-match-select-width": false,
                            options: row.serialNumberOptions,
                            loading: row.loadingSerialNumberOptions,
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_serial_number")
                            })
                          },
                          model: {
                            value: row.serialNumber,
                            callback: function($$v) {
                              _vm.$set(row, "serialNumber", $$v)
                            },
                            expression: "row.serialNumber"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "totalQty",
                    fn: function(_, row) {
                      return [
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          model: {
                            value: row.totalQty,
                            callback: function($$v) {
                              _vm.$set(row, "totalQty", $$v)
                            },
                            expression: "row.totalQty"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "uom",
                    fn: function(_, row) {
                      return [
                        _c("a-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "label-in-value": "",
                            "filter-option": false,
                            "dropdown-match-select-width": false,
                            options: row.uomOptions,
                            loading: row.loadingUOMOptions,
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_uom")
                            })
                          },
                          model: {
                            value: row.uom,
                            callback: function($$v) {
                              _vm.$set(row, "uom", $$v)
                            },
                            expression: "row.uom"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.pending },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_back")))]
                  ),
                  _vm.isNew
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "danger", loading: _vm.pending },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                      )
                    : _vm._e(),
                  _vm.$can("create", "warehouse-transfer") ||
                  _vm.$can("update", "warehouse-transfer")
                    ? _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.pending },
                          on: { click: _vm.handleSaveAsDraft }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_save_as_draft")))]
                      )
                    : _vm._e(),
                  _vm.$can("create", "warehouse-transfer") ||
                  _vm.$can("update", "warehouse-transfer")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.pending },
                          on: { click: _vm.handleCreate }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_submit")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }