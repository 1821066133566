import { generateUUID } from "@/helpers/uuid";
import { Option } from "@/models/class/option.class";
import { WarehouseTransferStatus } from "@/models/enums/warehouse-transfer.enum";
import { LabelInValue } from "@/types";
import moment, { Moment } from "moment";
import { InventoryLineResponseDto } from "../inventory";
import { ProductDetailDto } from "../master-product";
import { ProductPurchaseResponseDto } from "../ProductPurchaseResponse.interface";

export class CreateWarehouseTransferFormStateLine {
  rowId: string = generateUUID();

  id: string | null = null;
  part: LabelInValue | undefined = undefined;
  serialNumber: LabelInValue | undefined = undefined;
  totalQty = 0;
  uom: LabelInValue | undefined = undefined;

  loadingPartOptions = false;
  partOptions: Option<InventoryLineResponseDto>[] = [];
  usePartOptions = false;

  loadingSerialNumberOptions = false;
  serialNumberOptions: Option<ProductPurchaseResponseDto>[] = [];

  loadingUOMOptions = false;
  uomOptions: Option<ProductDetailDto>[] = [];
}

export class CreateWarehouseTransferFormState {
  from: LabelInValue | undefined = undefined;
  to: LabelInValue | undefined = undefined;
  date: Moment | null = moment();
  note = "";
  attachment: string | undefined = undefined;
  status: WarehouseTransferStatus = "NEW";
  warehouseTransferLines: CreateWarehouseTransferFormStateLine[] = [];

  deletedLineIds: string[] = [];
}
