var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    {
      attrs: {
        accept: ".pdf, .png, .jpg, .xlsx",
        "before-upload": _vm.handleBeforeUpload,
        "custom-request": _vm.handleUploadAttachment,
        "file-list": _vm.attachments,
        remove: _vm.handleRemoveAttachment
      }
    },
    [
      _c(
        "a-button",
        { attrs: { icon: "upload", loading: _vm.loading.uploadAttachment } },
        [_vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }