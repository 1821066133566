var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.$t("lbl_cancel"), visible: _vm.visible },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("a-button", { key: "back", on: { click: _vm.handleClose } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
              ]),
              _vm.$can("cancel", "result-work-order")
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "danger", loading: _vm.loading },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        { ref: "formModel", attrs: { model: _vm.form, rules: _vm.formRules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_reason"), prop: "reason" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.form.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "reason", $$v)
                  },
                  expression: "form.reason"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }