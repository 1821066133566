


















































































































































































































































































































































import quantityFilter from "@/filters/quantity.filter";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { useFindMasterType, useMinByDate } from "@/hooks";
import { ResultWorkOrderMapper } from "@/mapper/ResultWorkOrder.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import {
  ResultWorkOrderCreateRequestDto,
  ResultWorkOrderFormState,
} from "@/models/interface/result-work-order";
import { resultWorkOrderService } from "@/services/result-work-order.service";
import { ColumnDef } from "@/types";
import { StringUtils } from "@/utils/StringUtils";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";
import { mapState } from "vuex";
import CancelRWOModal from "./CancelRWOModal.vue";

export default Vue.extend({
  name: "ResultWorkOrderForm",
  mixins: [MNotification],
  components: {
    CancelRWOModal,
  },
  props: {
    workOrder: {
      required: false,
      default: undefined,
      type: Object as () => ResultWorkOrderFormState,
    },
    rwoId: {
      required: false,
      default: undefined,
      type: String,
    },
    loader: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      DATE_TIME_HOURS_DEFAULT_FORMAT,
      formState: this.workOrder,
      statusOptions: [] as Option[],
      reasonOptions: [] as Option[],
      form: null as FormModel | null,
      loading: {
        status: false,
        reason: false,
        submit: false,
        cancel: false,
      },
      columns: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          width: 50,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          width: 150,
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          width: 150,
        },
        {
          title: this.$t("lbl_category"),
          dataIndex: "category",
          width: 150,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          width: 100,
        },
        {
          title: this.$t("lbl_product_reserved"),
          dataIndex: "productReserved",
          width: 250,
        },
        {
          title: this.$t("lbl_quantity"),
          dataIndex: "productReservedQty",
          width: 100,
          customRender: (text: number) => quantityFilter(text),
        },
        {
          title: this.$t("lbl_uom"),
          dataIndex: "uom",
          width: 100,
        },
        {
          title: this.$t("lbl_returned_quantity"),
          dataIndex: "returnedQty",
          scopedSlots: { customRender: "returnedQty" },
          width: 200,
        },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "partNumber",
          width: 150,
        },
        {
          title: this.$t("lbl_track_as_asset"),
          dataIndex: "isTrackAsAsset",
          scopedSlots: { customRender: "isTrackAsAsset" },
          width: 150,
        },
      ] as ColumnDef[],
      modal: {
        show: false,
      },
    };
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    isEdit(): boolean {
      return !!this.rwoId;
    },
    isCancel(): boolean {
      return (
        !!this.formState.status &&
        StringUtils.compare(this.formState.status, "Cancelled")
      );
    },
  },
  watch: {
    workOrder: {
      handler(newValue: ResultWorkOrderFormState) {
        if (newValue) {
          this.formState = newValue;
        }
      },
    },
  },
  mounted() {
    this.getStatusList();

    if (this.$refs.form) {
      this.form = this.$refs.form as FormModel;
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    async getStatusList() {
      try {
        this.loading.status = true;
        const response = await useFindMasterType("STATUS_RWO");
        this.statusOptions = response.map(item => ({
          label: item.value,
          value: item.value,
          key: item.value,
        }));
      } catch {
        this.showNotifError("notif_process_fail");
        this.statusOptions = [];
      } finally {
        this.loading.status = false;
      }
    },
    async getReasonList(status: string) {
      try {
        const key = `STATUS_RWO_${trimSpaceToUnderscore(status)}`;
        this.loading.reason = true;
        const response = await useFindMasterType(key);
        this.reasonOptions = response.map(item => ({
          label: item.value,
          value: item.value,
          key: item.value,
        }));
      } catch {
        this.showNotifError("notif_process_fail");
        this.reasonOptions = [];
      } finally {
        this.loading.reason = false;
      }
    },
    onChangeStatus(value: string) {
      this.formState.reason = undefined;
      this.getReasonList(value);
    },
    minDateRules(current: Moment): boolean {
      return useMinByDate(current, moment(this.formState.workOrderDate));
    },
    handleBack() {
      this.$emit("back");
    },
    handleSubmit() {
      this.form?.validate(valid => {
        if (valid) {
          this.createRwo(this.formState);
        } else {
          this.showNotifWarning("notif_validation_error");
        }
      });
    },
    async createRwo(state: ResultWorkOrderFormState) {
      try {
        this.loading.submit = true;
        const request: ResultWorkOrderCreateRequestDto =
          ResultWorkOrderMapper.toCreateRequest(state);
        const response = await resultWorkOrderService.create(request);
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push({ name: "logistic.work-order.result" });
      } catch {
        this.showNotifError("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    handleCancel() {
      this.modal.show = true;
    },
    handleCloseModal() {
      this.modal.show = false;
    },
    handleCancelSuccess() {
      this.handleCloseModal();
      this.$emit("back");
    },
  },
});
